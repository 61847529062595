.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
p,h2{
  margin: 0;
  padding: 0;
}
body{
  background-color: #eee;
}

.back-content{
  background-color: #eee;
  width: 100%;
  height: 100vh;
}
.body-content{
  margin-left: 200px;
  margin-top: 7vh;
  transition: margin-left 0.3s ease-in-out;

}
.body-header{
  max-width: 100%;
  height:40px;
  display: flex;
  justify-content:space-between;
  vertical-align: middle;
  background-color: #fff;
  padding: 5px;

}
.main-content{
  display: flex;
  justify-content: center;
  vertical-align: middle;
  height: 100%;
  color: #282c34;
}
.page-content,.mypage-content{
  display: flex;
  justify-content: center;
}
.page-content{
  margin-top: 100px;
}
.mypage-content{
  margin-top: 16vh;
}
.common-form{
  padding: 50px;
  width: 500px;
  margin: auto 0;
}
.page-form{
  padding: 20px;
  width: 480px;
  margin: 0;
}
.form-title{
  text-align: center;
  font-size: 300%;
}
.page-title{
  background-color: #eee;
  padding:10px 0;
  padding-left: 20px;
  border-bottom: #ccc 3px solid;
  position: fixed;
  top:7vh;
  left: 200px;
  width: 100%;
  z-index: 1;
}
.user-content{
  margin: 20px 30px ;
}
.manual-content{
  margin: 5px 30px ;
}
.user-section{
  margin: 20px 0;
}
.user-title{
  font-weight: bold;
}
.user-text{
  margin-left: 5px;
  color: #444;
}
.user-title,.user-text{
  font-size: 110%;
}
.info-label{
  background-color: #61dafb;
  color: #282c34;
  padding: 10px;
}
.mypage-button{
  margin: 20px 5px;
}
.prev-button{
  margin: 0 10px;
}
.manual-button{
  margin: 20px 0;
}
.change-button{
  margin: auto 0;
  font-size: 100%;
  color: #444;
  font-weight: bold;
}
.signal-label{
  width: 100%;
  padding: 5px 0;
  text-align: center;
  color: #444;
  font-weight: bold;
}
.signal-text{
  margin: auto 0;
  font-weight: bold;
  font-size: 120%;
}
.btn-group{
  display: flex;
  justify-content: center;
}
.loading{
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.explanation-body{
  display: flex;
  vertical-align: middle;
  justify-content: center;
  background-color: #fff;
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}
.explanation-view{
  margin: auto 0;
  text-align: center;
  padding: 40px;
  display: flex;
  vertical-align: middle;
}
.explanation-view div{
  margin: auto 0;
}
.explanation-view ul{
  text-align: start;
}
.explanation-view li{
  list-style:decimal ;
}
.manual-link{
  font-size: 120%;
}
.manual-link p{
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .body-content{
    margin-left: 0;
  }
  .page-title{
    left: 0;
  }

}

@media (max-width: 526px) {
  .user-content{
    margin: 20px 5px ;
  }
  .page-content{
    margin-top: 50px;
  }
  .page-form{
    width: 330px;
    padding: 20px 5px;
  }
  .form-title{
    font-size: 200%;
  }
  .signal-text{
    font-size: 100%;
  }
  .explanation-view{
    padding: 40px 5px;
  }

}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact; /* これにより、背景色や色が正確に印刷されるようになります */
  }
  .body-header{
    display: none;
  }
  .body-content{
    margin:0;
  }
  .main-content,.back-content{
    height: auto;
  }

}
