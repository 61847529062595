.top-header{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 7vh;
    background-color: #444;
    z-index: 4;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    
}
.side-navigation{
    position: fixed;
    top:0;
    left:0;
    width: 200px;
    height: 100vh;
    background-color: #888;
    z-index: 3;
    overflow-y:auto;
    scrollbar-width: thin;
}
.menu-group{
    width:100%;
    margin:0;
    margin-top: 7vh;
    display:flex;
    flex-direction: column;
    
  }
.menu-item{
    padding:15px 0;
    padding-left:15px;
    cursor:pointer;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    color: #fff;
    text-decoration: none;
  }
  .menu-item:hover{
    background-color: #fff;
    color: #444;
  }
  .active{
    background-color: #fff;
    color: #444;
  }
  .ant-table-cell{
    position: relative;
  }
  .cell-content{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
  }
  .cell-content div{
    height: fit-content;
    margin: 0 1px;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
  }
  .ant-table-body{
    scrollbar-width: thin;
  }
  .ant-picker-panel-has-range:nth-child(2) ,.ant-picker-panel:nth-child(2){
    display: none;
  }
  .ant-picker-panel:nth-child(1) .ant-picker-header-next-btn {
    visibility: visible !important;
  }
  .ant-picker-panel:nth-child(1) .ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .date-pc{
    display: inline;
  }
  .date-smp{
    display: none;
  }
  .burger-btn{
    display: none;
    margin: auto 0;
    margin-left: 15px;
  }
  .black-view{
    position: fixed;
    background-color: rgba(0,0,0,1.0);
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }
  .black-view div{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  .app-title{
    color: #fff;
    margin: auto 0;
    margin-left: 10px;
  }

  @media (max-width: 1024px) {
    .burger-btn{
      display: block;
    }
    .app-title{
      margin-left: 0;
    }
  }

  @media (max-width: 880px) {
    .date-pc{
      display: none;
    }
    .date-smp{
      display: inline;
    }
    .print-btn{
      display: none;
    }
  }
  @media (max-width: 526px) {
    .side-navigation{
      width: 100%;
    }
    .app-title{
      font-size: 90%;
    }
  }

  @media print {
    body {
      -webkit-print-color-adjust: exact;
      color-adjust: exact; /* これにより、背景色や色が正確に印刷されるようになります */
    }
    .side-navigation, .top-header{
      display: none;
    }
    .ant-pagination, .ant-table-pagination, .ant-table-pagination-right{
      display: none !important;
    }
    .date-pc{
      display: none;
    }
    .date-smp{
      display: inline;
    }
    .filter-icon{
      display: none;
    }
    .ant-table-header {
      display: none;
    }
  }